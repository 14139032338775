import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {TreeNode} from 'primeng-lts/api';

export const TREE_SELECTION_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IamTreeSelectionComponent),
  multi: true
};

@Component({
  selector: 'iam-tree-selection',
  templateUrl: './iam-tree-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TREE_SELECTION_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focused'
  }
})
export class IamTreeSelectionComponent implements AfterContentInit, ControlValueAccessor {

  @Input() options: TreeNode[] = [];
  @Input() dataKey: string = '';
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Input() selectionWidth: string = '';

  value: any;

  onModelChange: Function = () => {
  };
  onModelTouched: Function = () => {
  };

  focused?: boolean;

  selectedNode?: TreeNode;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngAfterContentInit(): void {
  }

  writeValue(value: any): void {
    if (value) {
      this.findNode(value, this.options);
    }
    this.value = value;
    this.cd.markForCheck();
  }

  findNode(key: string, nodes: Array<TreeNode>): void {
    for (const node of nodes) {
      if (node.key === key) {
        this.selectedNode = node;
        break;
      } else if (node.children && node.children.length) {
        this.findNode(key, node.children);
      }
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  get filled(): boolean {
    return (this.value != null && this.value.toString().length > 0)
  }

  updateModel(): void {
    this.onModelChange(this.value);

    this.onModelTouched();
  }

  onSelectionChange(overlayPanel: any): void {
    overlayPanel.hide();
    if (this.selectedNode && this.selectedNode.data) {
      if (this.optionValue) {
        this.value = this.selectedNode.data[this.optionValue];
      } else {
        this.value = this.selectedNode.data;
      }
    }
    this.updateModel();
  }


}
