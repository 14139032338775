<form [formGroup]="form">
  <div class="p-fluid p-formgrid p-grid detail">
    <div class="p-field p-col-12 p-md-3">
      <label for="purchasedQuantity">{{ 'Số lượng đã mua'|translate }}</label>
      <p-inputNumber id="purchasedQuantity" formControlName="purchasedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="usedQuantity">{{ 'Số lượng đã sử dụng'|translate }}</label>
      <p-inputNumber id="usedQuantity" formControlName="usedQuantity"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-5">
      <label for="purchasedTimePeriod">{{ 'Thời gian mua'|translate }}</label>
      <p-inputNumber id="purchasedTimePeriod" formControlName="purchasedTimePeriod"></p-inputNumber>
    </div>
    <div class="p-field p-col-12 p-md-1 p-pt-5">
      <span *ngIf="form.get('timeUomId')?.value">({{ form.get('timeUomId')?.value|translate }})</span>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="effectiveDate">{{ 'Ngày bắt đầu sử dụng'|translate }}</label>
      <p-calendar id="effectiveDate" formControlName="effectiveDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <label for="expireDate">{{ 'Ngày hết hạn'|translate }}</label>
      <p-calendar id="expireDate" formControlName="expireDate" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="p-field p-col-12">
      <p-table [value]="form.get('items')?.value" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="header">
          <tr>
            <th [width]="40" class="p-text-center">#</th>
            <th>Gói</th>
            <th>Ghi chú</th>
            <th [width]="180">Ngày bắt đầu</th>
            <th>Đã sử dụng</th>
            <th>{{ 'Status'|translate }}</th>
            <th [width]="180"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr>
            <td class="p-text-center">{{ (index + 1)|number }}</td>
            <td>{{ rowData['productName'] }}</td>
            <td>{{ rowData['comments'] }}</td>
            <td>{{ rowData['fromDate']|date:'dd/MM/yyyy HH:mm:ss' }}</td>
            <td class="p-text-right">{{ rowData['issuedQuantity']|number }}</td>
            <td>{{ rowData['status'] }}</td>
            <td>
              <ng-container *ngIf="rowData['statusId'] === 'PpInactive'">
                <p-button label="Kích hoạt" styleClass="p-button-raised p-button-sm" (onClick)="activate(rowData)"></p-button>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</form>
