import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {CertificatedService} from './certificated.service';
import {ToastService} from '../../../../shared/components/toast/toast.service';

@Component({
  selector: 'app-certificated',
  templateUrl: './certificated.component.html'
})
export class CertificatedComponent implements OnInit, OnDestroy {

  displaySubscription?: Subscription;
  errorSubscription?: Subscription;
  displaySignProgress?: number;
  pluginNotFound?: boolean;
  display?: boolean;


  constructor(
    private certificatedService: CertificatedService,
    private toastService: ToastService
  ) {
  }

  onSignEnd(): void {
    this.toastService.success();
  }

  ngOnInit(): void {
    this.errorSubscription = this.certificatedService.errorSubject.subscribe(error => {
      if (error) {
        if (error.status === 0) {
          this.pluginNotFound = true;
        }
        if (error?.name === "TimeoutError") {
          this.pluginNotFound = true
        }
        console.log(error);


      }
    });
    this.displaySubscription = this.certificatedService.displaySubject.subscribe(res => {
      if (res === 'open') {
        this.display = true
      } else if (res === 'close') {
        this.display = false
      } else if (res === 'signProgress') {
        this.displaySignProgress = Date.now();
      }
    });

  }

  ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.displaySubscription) {
      this.displaySubscription.unsubscribe();
    }

  }

}
