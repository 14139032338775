<div class="layout-wrapper" (click)="onLayoutClick()">
  <app-topbar [activeModule]="activeModule"></app-topbar>
  <div class="menu-wrapper">
    <app-menu [activeModule]="activeModule"></app-menu>
  </div>
  <div class="layout-main">
    <app-breadcrumb></app-breadcrumb>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-certificated></app-certificated>
</div>
