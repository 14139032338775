import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';

import {Subject} from 'rxjs';

import {LoginService} from '../../../../pages/login/login.service';
import {AppService, UserService} from '../../../../shared/service/user.service';

import {ConfirmService} from '../../../../shared/components/confirm/confirm.service';
import {timeout} from 'rxjs/operators';
import {ToastService} from '../../../../shared/components/toast/toast.service';

interface RunValue {
  idValue: string
  actionType: string
  taxCode?: string
  requireLoginKey?: boolean
}

@Injectable({
  providedIn: `root`
})
export class CertificatedService {
  readonly errorSubject: Subject<any>;
  readonly displaySubject: Subject<any>;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    private confirmService: ConfirmService,
    private loginService: LoginService,
    private toastService: ToastService
  ) {
    this.http = new HttpClient(this.backend);
    this.errorSubject = new Subject<any>();
    this.displaySubject = new Subject<any>();
  }

  run(runValue: RunValue): Promise<void> {

    return new Promise((resolve, reject) => {

      this.http.get('http://localhost:13589/test').pipe(timeout(1500)).subscribe({
        next: (res: any) => {
          if (res?.receiveDataFromBrowser === "Y") {
            const data = {
              signingType: runValue.actionType,
              idValue: runValue.idValue,
              taxCode: runValue.taxCode || UserService.getUser().partyTaxId,
              responseContentType: "application/json"
            };
            const url = window.location.origin + '/tax/rest/s1';
            this.http.put(`${url}/iam-common/signing`, data, {headers: {"x-csrf-token": AppService.get('MOQUI_SESSION_TOKEN')}}).subscribe({
              next: (result: any) => {
                this.displaySubject.next('open');
                this.http.post('http://localhost:13589/signing', result).subscribe({
                  next: (response: any) => {
                    if (response?.success == true) {
                      this.http.post(`${url}/iam-common/complete-signing`, response, {headers: {"x-csrf-token": AppService.get('MOQUI_SESSION_TOKEN')}},).subscribe({
                        next: (finalResult: any) => {
                          if (finalResult?.idValue) {
                            this.toastService.success();
                            this.displaySubject.next('close');
                            resolve();
                          } else if (finalResult?.errorMessage) {
                            this.confirmService.setConfirmDialog({
                              message: `[${finalResult?.errorCode}] ${finalResult?.errorMessage}`,
                              header: 'Ký điện tử không thành công',
                              icon: 'pi pi-exclamation-triangle',
                              rejectLabel: 'Đóng',
                              acceptVisible: false
                            });
                            this.displaySubject.next('close');
                            reject();
                          } else {
                            reject();
                          }
                        }, error: error => {
                          this.confirmService.setConfirmDialog({
                            message: `[${error?.error.errorCode}] ${error?.error.errors}`,
                            header: 'Ký điện tử không thành công',
                            icon: 'pi pi-exclamation-triangle',
                            rejectLabel: 'Đóng',
                            acceptVisible: false
                          });
                          this.displaySubject.next('close');
                          reject();
                        }
                      })
                    } else if (response?.success == false) {
                      const input = {
                        success: "N",
                        idValue: runValue.idValue,
                        signingType: runValue.actionType
                      }
                      this.confirmService.setConfirmDialog({
                        message: `[${response.errorCode}] ${response.errorMessage}`,
                        header: 'Ký điện tử không thành công',
                        icon: 'pi pi-exclamation-triangle',
                        rejectLabel: 'Đóng',
                        acceptVisible: false
                      });
                      this.displaySubject.next('close');
                      this.http.post(`${url}/iam-common/complete-signing`, input, {headers: {"x-csrf-token": AppService.get('MOQUI_SESSION_TOKEN')}},).subscribe({
                        next: (finalResult: any) => {
                          this.errorSubject.next(finalResult);
                          reject();
                        },
                      })
                      reject();
                    } else {
                      reject();
                    }
                  }, error: error => {
                    this.errorSubject.next(error);
                    this.displaySubject.next('close');
                    reject();
                  }
                })
              }, error: error => {
                this.confirmService.setConfirmDialog({
                  message: `[${error?.error.errorCode}] ${error?.error.errors}`,
                  header: 'Ký điện tử không thành công',
                  icon: 'pi pi-exclamation-triangle',
                  rejectLabel: 'Đóng',
                  acceptVisible: false
                });
                reject();
              }
            })
          } else {
            // this.loginService.getApiKey().subscribe((response) => {
            //   if (response.token) {
            //     location.href = `${AppService.KEY.SIGN}:${response.token},${AppService.get(AppService.KEY.MOQUI_SESSION_TOKEN)},${runValue.actionType},${runValue.idValue}`;
            //     this.displaySignProgress = Date.now();
            //   }
            // });
          }
        },
        error: (err: any) => {
          reject();
          console.log(err)
          this.errorSubject.next(err);
          this.displaySubject.next('signProgress')
          this.loginService.getApiKey().subscribe((response) => {
            if (response.token) {
              location.href = `${AppService.KEY.SIGN}:${response.token},${AppService.get(AppService.KEY.MOQUI_SESSION_TOKEN)},${runValue.actionType},${runValue.idValue}`;
            }
          });
        }
      })
    });
  }


}
