import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private readonly url = 'iam-common/geos';

  constructor(private http: HttpClient) {
  }

  getCountries(): Observable<any> {
    return this.http.get(`${this.url}/countries`);
  }

  getProvinceAssocs(countryGeoId?: string): Observable<any> {
    return this.http.get(`${this.url}/${countryGeoId || 'VNM'}/assocs?toGeoTypeEnumId=GEOT_PROVINCE`);
  }

  getProvinces(): Observable<any> {
    return this.http.get(`${this.url}/provinces`);
  }

  getAssocs(geoId: string): Observable<any> {
    return this.http.get(`${this.url}/${geoId}/assocs`);
  }

  getTaxAuthorities(geoId: string): Observable<any> {
    return this.http.get(`${this.url}/${geoId}/tax-authority`);
  }

  getEmploymentStatuses(): Observable<any> {
    return this.http.get(`iam-common/working-status`);
  }

  getIncomeTypes(): Observable<any> {
    return this.http.get(`iam-common/income-type`);
  }

}
