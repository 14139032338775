<!--<app-iam-table [cols]="cols" [actionColumnWidth]="40" header="Lịch sử thay đổi" dataKey="changedDate"-->
<!--               [items]="items" [totalRecords]="totalRecords" [loadLazy]="loadLazy"></app-iam-table>-->

<p-table [value]="items" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      <th [width]="40" class="p-text-center">#</th>
      <th [width]="160">{{ 'Date'|translate }}</th>
      <th>{{ 'Trường thay đổi'|translate }}</th>
      <th>{{ 'Giá trị cũ'|translate }}</th>
      <th>{{ 'Giá trị mới'|translate }}</th>
      <th>{{ 'Username'|translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-index="rowIndex">
    <tr>
      <td class="p-text-center">{{ (index + 1)|number }}</td>
      <td>{{ rowData['changedDate']|date:'dd/MM/yyyy HH:mm:ss' }}</td>
      <td>{{ rowData['fieldDesc'] }}</td>
      <td>{{ rowData['oldValueText'] }}</td>
      <td>{{ rowData['newValueText'] }}</td>
      <td>{{ rowData['userFullName'] }}</td>
    </tr>
  </ng-template>
</p-table>
