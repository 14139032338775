import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {ItemsService} from './items.service';
import {validateAllFormFields} from '../../../shared/components/validator/validator.service';
import {ToastService} from '../../../shared/components/toast/toast.service';

import {IamComponent} from '../../abstract/IamComponent';
import {CommonService} from '../common/common.service';

@Component({
  selector: 'app-root-info',
  templateUrl: './items.component.html'
})
export class ItemsComponent extends IamComponent implements OnInit {

  submitted: boolean = false;
  form = this.fb.group({
    partyTaxId: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.@-]*$/)]],
    organizationName: ['', Validators.required],
    webAddress: [''],
    emailAddress: [''],
    phoneNumber: [''],
    address1: ['', Validators.required],
    stateProvinceGeoId: ['', Validators.required],
    countyGeoId: ['', Validators.required],
    taxAuthorityId: ['', Validators.required],
    taxAuthorityName: [''],

    repPartyName: ['']
  });
  provinces: Array<any> = new Array<any>();

  updating?: boolean;

  constructor(
    private fb: FormBuilder,
    private itemsService: ItemsService,
    private commonService: CommonService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    const init = [];
    init.push(new Promise<void>((resolve) => {
      this.commonService.getProvinces().subscribe(({listData}) => {
        listData.forEach((item: any) => {
          this.provinces.push({value: item.toGeoId, label: item.geoName});
        });
        resolve();
      });
    }));
    Promise.all(init).then(() => {
      this.load();
    });
  }

  load(): void {
    this.itemsService.get().subscribe((response) => {
      this.form.reset();

      this.form.patchValue(response);
      this.form.disable();
    });
  }

  update(): void {
    this.updating = true;

    this.form.enable();
  }

  cancelUpdate(): void {
    this.updating = false;

    this.form.disable();
  }

  onSubmit(): void {
    if (!this.submitted && validateAllFormFields(this.form)) {
      this.submitted = true;

      this.itemsService.set(this.form.value).subscribe((response) => {
        this.submitted = false;
        if (response && response.partyId) {
          this.toastService.success();
          this.cancelUpdate();
        } else {
          this.toastService.error();
        }
      }, () => {
        this.submitted = false;
      });
    }
  }

}
