<p-dialog header="Ký điện tử không thành công" [(visible)]="pluginNotFound" [style]="{width: '580px'}" [resizable]="false">
  <div class="p-grid">
    <div class="p-col-2 p-text-center">
      <img src="/assets/images/usb-not-connected-icon.png" alt="">
    </div>
    <div class="p-col-10">
      <p><b>Hãy kiểm tra các trường hợp dưới đây để khắc phục vấn đề của bạn</b></p>
      <p><b>1.</b> Bạn chưa cài đặt phần mềm ký điện tử My Invoice hoặc bản cài của bạn cần cập nhật</p>
      <p class="p-pl-4">Hãy cài đặt phần mềm ký điện tử My Invoice để tiếp tục.</p>
      <p class="p-pl-4"><a target="_blank" rel="noopener noreferrer" download="MsSetupTools.zip" href="assets/tools/MsSetupTools.zip"><i class="pi pi-download"></i> Tải bộ cài</a></p>

      <p><b>2.</b> Bạn đã cài đặt công cụ <b>Ký số điện tử My Invoice</b></p>
      <p class="p-pl-4">Hãy mở công cụ <b>Ký số điện tử My Invoice</b> trước khi ký điện tử</p>
    </div>
  </div>
  <p-footer>
    <p-button label="{{ 'Close'|translate }}" styleClass="p-button-raised" (onClick)="pluginNotFound=false"></p-button>
  </p-footer>
</p-dialog>
<p-dialog [(visible)]="display" [modal]="true" [closable]="false" [style]="{width: '500px', height:'200px'}" styleClass="no-scroll" [resizable]="false">
  <div [style]="{textAlign:'center'}">
    <div class="spinner"></div>
    <div class="message2">
      <b>Đang ký xác thực...</b>
    </div>
  </div>
</p-dialog>
