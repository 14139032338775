<div *ngIf="control && control.invalid && control.touched">
  <div *ngIf="control.errors?.['required']">
    <small class="p-error">{{ 'FieldIsRequired'|translate }}</small>
  </div>
  <div *ngIf="control.errors?.['email']">
    <small class="p-error">{{ 'EmailInvalid'|translate }}</small>
  </div>
  <div *ngIf="control.errors?.['pattern']">
    <small class="p-error">{{ 'FormatInvalid'|translate }}</small>
  </div>
  <!--  <p>{{ control.errors|json }}</p>-->
</div>
