<form [formGroup]="form">
  <div class="paper">
    <p-card styleClass="p-pl-3 p-pr-3">
      <div class="print-content">
        <div class="p-grid">
          <div class="p-col-12 p-text-center p-text-uppercase">
            <h4 class="p-mb-0">TỜ KHAI</h4>
          </div>
          <div class="p-col-12 p-pt-0 p-text-center">
            <b>Đăng ký/Thay đổi thông tin sử dụng hóa đơn điện tử</b>
          </div>
        </div>
        <div class="p-fluid p-mt-2 detail">
          <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:155px">{{ 'Tên người nộp thuế'|translate }}:</label>
            <div class="p-col">
              <span>{{ form.get('organizationPartyName')?.value }}</span>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:155px">{{ 'TaxCode'|translate }}:</label>
            <div class="p-col tax-code" *ngIf="form.get('organizationPartyTaxIdArray')?.value">
              <div *ngFor="let item of form.get('organizationPartyTaxIdArray')?.value">
                {{item}}
              </div>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed" style="width:155px">{{ 'Cơ quan thuế quản lý'|translate }}:</label>
            <div class="p-col">
              <span>{{ form.get('taxAuthorityName')?.value }}</span>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="orgRepName" class="p-col-fixed" style="width:155px">{{ 'Người liên hệ'|translate }}:</label>
            <div class="p-col">
              <input id="orgRepName" pInputText type="text" formControlName="orgRepName" class="iam-input">
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="organizationPhoneNumber" class="p-col-fixed" style="width:155px">{{ 'Điện thoại liên hệ'|translate }}:</label>
            <div class="p-col">
              <input id="organizationPhoneNumber" pInputText type="text" formControlName="organizationPhoneNumber" class="iam-input">
            </div>
            <label for="organizationEmailAddress" class="p-col-fixed" style="width:115px">{{ 'Thư điện tử'|translate }}:</label>
            <div class="p-col">
              <input id="organizationEmailAddress" pInputText type="text" formControlName="organizationEmailAddress" class="iam-input">
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="organizationPartyAddr" class="p-col-fixed" style="width:155px">{{ 'Địa chỉ liên hệ'|translate }}:</label>
            <div class="p-col">
              <input id="organizationPartyAddr" pInputText type="text" formControlName="organizationPartyAddr" class="iam-input">
            </div>
          </div>
        </div>
        <div class="p-grid p-mt-2">
          <div class="p-col-12">
            <p>Theo nghị định số 123/2020/NĐ-CP ngày 19 tháng 10 năm 2020 của chính phủ, chúng tôi/tôi thuộc đối tượng sử dụng hóa đơn điện tử. Chúng tôi/tôi đăng ký/thay đổi thông tin đã đăng ký với cơ quan thuế về việc sử dụng hóa đơn điện tử như sau:</p>
          </div>
        </div>
        <ng-container formGroupName="itemTerms">
          <div class="p-grid">
            <div class="p-col-12">
              <b>1. Hình thức hóa đơn:</b>
            </div>
            <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox formControlName="formWithTaxAuthCode" [binary]="true" inputId="formWithTaxAuthCode"></p-checkbox>
                <label for="formWithTaxAuthCode">Có mã của cơ quan thuế</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox formControlName="formWithoutTaxAuthCode" [binary]="true" inputId="formWithoutTaxAuthCode"></p-checkbox>
                <label for="formWithoutTaxAuthCode">Không có mã của cơ quan thuế</label>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <b>2. Hình thức gửi dữ liệu hóa đơn điện tử:</b>
            </div>
            <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox formControlName="transferTypeWithTaxAuthCode" [binary]="true" inputId="transferTypeWithTaxAuthCode"></p-checkbox>
                <label for="transferTypeWithTaxAuthCode">a. Trường hợp sử dụng hóa đơn điện tử có mã không phải trả tiền dịch vụ theo khoản 1 Điều 14 của Nghị định:</label>
              </div>
              <div class="p-col-12 p-pl-5">
                <div class="p-field-checkbox">
                  <p-checkbox formControlName="transTacDiaBanKK" [binary]="true" inputId="transTacDiaBanKK"></p-checkbox>
                  <label for="transTacDiaBanKK">Doanh nghiệp nhỏ và vừa, hợp tác xã, hộ, cá nhân kinh doanh tại địa bàn có điều kiện kinh tế xã hội khó khăn, địa bàn có điều kiện kinh tế xã hội đặc biệt khó khăn.</label>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox formControlName="transTacUbnd" [binary]="true" inputId="transTacUbnd"></p-checkbox>
                  <label for="transTacUbnd">Doanh nghiệp nhỏ và vừa khác theo đề nghị của Ủy ban nhân dân tỉnh, thành phố trực thuộc trung ương gửi Bộ Tài chính trừ doanh nghiệp hoạt động tại các khu kinh tế, khu công nghiệp, khu công nghệ cao.</label>
                </div>
              </div>
            </div>
            <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox formControlName="transferTypeWithoutTaxAuthCode" [binary]="true" inputId="transferTypeWithoutTaxAuthCode"></p-checkbox>
                <label for="transferTypeWithoutTaxAuthCode">b. Trường hợp sử dụng hóa đơn điện tử không có mã của cơ quan thuế:</label>
              </div>
              <div class="p-col-12 p-pl-5">
                <div class="p-field-checkbox">
                  <p-checkbox formControlName="transNoTacDirect" [binary]="true" inputId="transNoTacDirect"></p-checkbox>
                  <label for="transNoTacDirect">Chuyển dữ liệu hóa đơn điện tử trực tiếp đến cơ quan thuế (điểm b1, khoản 3, Điều 22 của Nghị định).</label>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox formControlName="transNoTacIndirect" [binary]="true" inputId="transNoTacIndirect"></p-checkbox>
                  <label for="transNoTacIndirect">Thông qua tổ chức cung cấp dịch vụ hóa đơn điện tử (điểm b2, khoản 3, Điều 22 của Nghị định).</label>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <b>3. Phương thức chuyển dữ liệu hóa đơn điện tử:</b>
            </div>
            <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox formControlName="transferMethodDetail" [binary]="true" inputId="transferMethodDetail"></p-checkbox>
                <label for="transferMethodDetail">Chuyển đầy đủ nội dung từng hóa đơn.</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox formControlName="transferMethodSummary" [binary]="true" inputId="transferMethodSummary"></p-checkbox>
                <label for="transferMethodSummary">Chuyển theo bảng tổng hợp dữ liệu hóa đơn điện tử (điểm a1, khoản 3, Điều 22 của Nghị định).</label>
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <b>4. Loại hóa đơn sử dụng</b>
            </div>
            <div class="p-col-12">
              <div class="p-field-checkbox">
                <p-checkbox formControlName="usingVat" [binary]="true" inputId="usingVat"></p-checkbox>
                <label for="usingVat">Hóa đơn GTGT</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox formControlName="usingSale" [binary]="true" inputId="usingSale"></p-checkbox>
                <label for="usingSale">Hóa đơn bán hàng</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox formControlName="usingOther" [binary]="true" inputId="usingOther"></p-checkbox>
                <label for="usingOther">Các loại hóa đơn khác</label>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox formControlName="usingBill" [binary]="true" inputId="usingBill"></p-checkbox>
                <label for="usingBill">Các chứng từ được in, phát hành, sử dụng và quản lý như hóa đơn</label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </p-card>
  </div>
  <div class="p-grid no-print" style="background-color: #f7f7f7;padding: 3px 12px;border-top: 2px solid #cfd9e0;position: fixed;right: 0;bottom: 0;width: 100%;">
    <div class="p-col-12 p-text-center">
      <p-button label="{{ 'Print'|translate }}" icon="pi pi-print" styleClass="p-button-outlined p-button-secondary"
                (onClick)="print()"></p-button>
    </div>
  </div>
</form>
