import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {RouteGuard} from './shared/guard/route.guard';

import {EntitiesComponent} from './entities/entities.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)},
  {path: 'invoice-info/:type/:id', loadChildren: () => import('./pages/invoice-info/invoice-info.module').then(mod => mod.InvoiceInfoModule)},
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {path: 'home', loadChildren: () => import('./entities/home/home.module').then(mod => mod.HomeModule)},
      {
        path: 'sold',
        loadChildren: () => import('./entities/sold/sold.module').then(mod => mod.SoldModule),
        canActivate: [RouteGuard],
        canActivateChild: [RouteGuard]
      },
      {path: 'purchase', loadChildren: () => import('./entities/purchase/purchase.module').then(mod => mod.PurchaseModule)},
      {path: 'personal-income-tax', loadChildren: () => import('./entities/personal-income-tax/personal-income-tax.module').then(mod => mod.PersonalIncomeTaxModule)},
      {path: '**', redirectTo: '/personal-income-tax/tax-deduction', pathMatch: 'full'}
    ]
  },
  {path: '**', redirectTo: '/personal-income-tax/tax-deduction'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
