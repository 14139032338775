import {BehaviorSubject} from 'rxjs';

export class AppSubject {

  private static readonly decreeCircular = new BehaviorSubject<string>('');

  static getDecreeCircular(): BehaviorSubject<string> {
    return this.decreeCircular;
  }

  static setDecreeCircular(decreeCircular: string): void {
    this.decreeCircular.next(decreeCircular);
  }

}
