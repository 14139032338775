import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'iam-invoice/sellers';

  constructor(private http: HttpClient) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.url}/self`);
  }

  set(item: any): Observable<any> {
    return this.http.put(`${this.url}/self`, item);
  }

  getChangelogs(lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${this.url}/changing-log?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getConsumeReview(): Observable<any> {
    return this.http.get(`${this.url}/consume-review`);
  }

  getMessage100Accepted(): Observable<any> {
    return this.http.get(`${this.url}/message-100-accepted`);
  }

  getPackageMgrInfo(): Observable<any> {
    return this.http.get(`iam-product/package-mgr/info`);
  }

  activatePackageMgrInfo(productId: string): Observable<any> {
    return this.http.put(`iam-product/package-mgr/active`, {productId});
  }

}
