import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  private readonly url = 'iam-party/organization-unit/root-info';

  constructor(private http: HttpClient) {
  }

  get(): Observable<any> {
    return this.http.get(`${this.url}`);
  }

  set(item: any): Observable<any> {
    return this.http.put(`${this.url}`, item);
  }

}
