<div class="p-dropdown p-component" (click)="opDate.toggle($event)">
  <span class="p-element p-dropdown-label p-inputtext">
    <ng-container *ngIf="selectedNode">
      {{ selectedNode.data[optionLabel] }}
    </ng-container>
    <ng-container *ngIf="!selectedNode">-</ng-container>
  </span>
  <div role="button" class="p-dropdown-trigger">
    <span class="p-dropdown-trigger-icon pi pi-chevron-down"></span>
  </div>
</div>
<p-overlayPanel #opDate [style]="{width: selectionWidth}">
  <ng-template pTemplate>
    <p-treeTable [value]="options" selectionMode="single" [(selection)]="selectedNode" [dataKey]="dataKey"
                 (selectionChange)="onSelectionChange(opDate)">
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{ rowData[optionLabel] }}
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </ng-template>
</p-overlayPanel>
